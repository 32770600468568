exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-apps-free-ai-image-generator-tsx": () => import("./../../../src/pages/apps/free-ai-image-generator.tsx" /* webpackChunkName: "component---src-pages-apps-free-ai-image-generator-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-case-study-extreme-go-personal-voice-assistant-tsx": () => import("./../../../src/pages/case-study/extreme-go-personal-voice-assistant.tsx" /* webpackChunkName: "component---src-pages-case-study-extreme-go-personal-voice-assistant-tsx" */),
  "component---src-pages-case-study-extreme-personal-voice-assistant-tsx": () => import("./../../../src/pages/case-study/extreme-personal-voice-assistant.tsx" /* webpackChunkName: "component---src-pages-case-study-extreme-personal-voice-assistant-tsx" */),
  "component---src-pages-case-study-friday-personal-voice-assistant-tsx": () => import("./../../../src/pages/case-study/friday-personal-voice-assistant.tsx" /* webpackChunkName: "component---src-pages-case-study-friday-personal-voice-assistant-tsx" */),
  "component---src-pages-case-study-healstation-organization-mental-health-mobile-app-tsx": () => import("./../../../src/pages/case-study/healstation-organization-mental-health-mobile-app.tsx" /* webpackChunkName: "component---src-pages-case-study-healstation-organization-mental-health-mobile-app-tsx" */),
  "component---src-pages-case-study-index-tsx": () => import("./../../../src/pages/case-study/index.tsx" /* webpackChunkName: "component---src-pages-case-study-index-tsx" */),
  "component---src-pages-case-study-meditation-mobile-app-tsx": () => import("./../../../src/pages/case-study/meditation-mobile-app.tsx" /* webpackChunkName: "component---src-pages-case-study-meditation-mobile-app-tsx" */),
  "component---src-pages-case-study-wizpay-mobile-banking-app-development-tsx": () => import("./../../../src/pages/case-study/wizpay-mobile-banking-app-development.tsx" /* webpackChunkName: "component---src-pages-case-study-wizpay-mobile-banking-app-development-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-frequently-asked-questions-tsx": () => import("./../../../src/pages/frequently-asked-questions.tsx" /* webpackChunkName: "component---src-pages-frequently-asked-questions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-tsx": () => import("./../../../src/pages/industries.tsx" /* webpackChunkName: "component---src-pages-industries-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-privacy-privacy-policy-extreme-tsx": () => import("./../../../src/pages/privacy/privacy-policy-extreme.tsx" /* webpackChunkName: "component---src-pages-privacy-privacy-policy-extreme-tsx" */),
  "component---src-pages-privacy-privacy-policy-friday-tsx": () => import("./../../../src/pages/privacy/privacy-policy-friday.tsx" /* webpackChunkName: "component---src-pages-privacy-privacy-policy-friday-tsx" */),
  "component---src-pages-privacy-privacy-policy-multiverse-tsx": () => import("./../../../src/pages/privacy/privacy-policy-multiverse.tsx" /* webpackChunkName: "component---src-pages-privacy-privacy-policy-multiverse-tsx" */),
  "component---src-pages-privacy-privacy-policy-notes-tsx": () => import("./../../../src/pages/privacy/privacy-policy-notes.tsx" /* webpackChunkName: "component---src-pages-privacy-privacy-policy-notes-tsx" */),
  "component---src-pages-privacy-privacy-policy-ullu-tsx": () => import("./../../../src/pages/privacy/privacy-policy-ullu.tsx" /* webpackChunkName: "component---src-pages-privacy-privacy-policy-ullu-tsx" */),
  "component---src-pages-privacy-privacy-policy-walldrox-tsx": () => import("./../../../src/pages/privacy/privacy-policy-walldrox.tsx" /* webpackChunkName: "component---src-pages-privacy-privacy-policy-walldrox-tsx" */),
  "component---src-pages-reviews-awards-tsx": () => import("./../../../src/pages/reviews-awards.tsx" /* webpackChunkName: "component---src-pages-reviews-awards-tsx" */),
  "component---src-pages-services-artificial-intelligence-tsx": () => import("./../../../src/pages/services/artificial-intelligence.tsx" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-tsx" */),
  "component---src-pages-services-custom-application-development-tsx": () => import("./../../../src/pages/services/custom-application-development.tsx" /* webpackChunkName: "component---src-pages-services-custom-application-development-tsx" */),
  "component---src-pages-services-flutter-application-development-tsx": () => import("./../../../src/pages/services/flutter-application-development.tsx" /* webpackChunkName: "component---src-pages-services-flutter-application-development-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-it-services-tsx": () => import("./../../../src/pages/services/it-services.tsx" /* webpackChunkName: "component---src-pages-services-it-services-tsx" */),
  "component---src-pages-services-mobile-app-development-tsx": () => import("./../../../src/pages/services/mobile-app-development.tsx" /* webpackChunkName: "component---src-pages-services-mobile-app-development-tsx" */),
  "component---src-pages-services-mobile-application-development-tsx": () => import("./../../../src/pages/services/mobile-application-development.tsx" /* webpackChunkName: "component---src-pages-services-mobile-application-development-tsx" */),
  "component---src-pages-services-react-native-application-development-tsx": () => import("./../../../src/pages/services/react-native-application-development.tsx" /* webpackChunkName: "component---src-pages-services-react-native-application-development-tsx" */),
  "component---src-pages-services-ui-ux-design-tsx": () => import("./../../../src/pages/services/ui-ux-design.tsx" /* webpackChunkName: "component---src-pages-services-ui-ux-design-tsx" */),
  "component---src-pages-services-web-application-development-tsx": () => import("./../../../src/pages/services/web-application-development.tsx" /* webpackChunkName: "component---src-pages-services-web-application-development-tsx" */),
  "component---src-pages-thank-you-for-contacting-tsx": () => import("./../../../src/pages/thank-you-for-contacting.tsx" /* webpackChunkName: "component---src-pages-thank-you-for-contacting-tsx" */),
  "component---src-pages-why-us-tsx": () => import("./../../../src/pages/why-us.tsx" /* webpackChunkName: "component---src-pages-why-us-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/BlogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-regional-service-page-template-tsx": () => import("./../../../src/templates/RegionalServicePageTemplate.tsx" /* webpackChunkName: "component---src-templates-regional-service-page-template-tsx" */)
}

